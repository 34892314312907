import { render, staticRenderFns } from "./app-details.vue?vue&type=template&id=58c468eb"
import script from "./app-details.vue?vue&type=script&lang=js"
export * from "./app-details.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNavBar: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/TopNavBar.vue').default,LoginPopup: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/LoginPopup.vue').default,FooterLoginPopup: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/FooterLoginPopup.vue').default,GeneralScripts: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/GeneralScripts.vue').default,CreateCollectionPopup: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/CreateCollectionPopup.vue').default,SelectCollectionPopup: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/SelectCollectionPopup.vue').default,Onboarding: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/Onboarding.vue').default,LifetimePopup: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/LifetimePopup.vue').default,UpgradePopup: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/UpgradePopup.vue').default,MagicSearch: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/MagicSearch/MagicSearch.vue').default})
